class BaseUseClass {
    constructor() {
        this.isEditMode = this.isEditMode.bind(this);
        this.isPreviewMode = this.isPreviewMode.bind(this);
        this.isBusinessPage = this.isBusinessPage.bind(this);
        this.getHeaderHeight = this.getHeaderHeight.bind(this);
        this.getBannerHeight = this.getBannerHeight.bind(this);
        this.getFixedContainerHeight = this.getFixedContainerHeight.bind(this);
        this.onElementHeightChange = this.onElementHeightChange.bind(this);
        this.elementIsVisible = this.elementIsVisible.bind(this);
        this.isMobile = this.isMobile.bind(this);
        this.isTablet = this.isTablet.bind(this);
        this.isSmallDesktop = this.isSmallDesktop.bind(this);
        this.isDesktop = this.isDesktop.bind(this);
        this.generateUuidv4 = this.generateUuidv4.bind(this);
        this.setCookie = this.setCookie.bind(this);
        this.getCookie = this.getCookie.bind(this);
        this.deleteCookie = this.deleteCookie.bind(this);
        this.applyTabFocusRestrictor = this.applyTabFocusRestrictor.bind(this);
        this.tabFocusRestrictor = this.tabFocusRestrictor.bind(this);
        this.killTabFocusRestrictor = this.killTabFocusRestrictor.bind(this);
        this.elementJump = this.elementJump.bind(this);
        this.ensureVisibility = this.ensureVisibility.bind(this);
        this.isWebPSupported = this.isWebPSupported.bind(this);
        this.callLinkCallback = this.callLinkCallback.bind(this);
        this.isIOS = this.isIOS.bind(this);
        this.log = this.log.bind(this);
        this.focusableSelector = 'div[tabindex]:not(:hidden),'
            + ' a[tabindex]:not(:hidden), span[tabindex]:not(:hidden), a[href]:not(:hidden):not([disabled]),'
            + ' button:not(:hidden):not([disabled]), textarea:not(:hidden):not([disabled]),'
            + ' input[type="text"]:not(:hidden):not([disabled]), input[type="radio"]:not(:hidden):not([disabled]),'
            + ' input[type="checkbox"]:not(:hidden):not([disabled]), select:not(:hidden):not([disabled])';
        this.focusableSelectorHidden = 'div[tabindex],'
            + ' a[tabindex], span[tabindex], a[href]:not([disabled]),'
            + ' button:not([disabled]), textarea:not([disabled]),'
            + ' input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]),'
            + ' input[type="checkbox"]:not([disabled]), select:not([disabled])';
        this.provideResizeEndEvent = this.provideResizeEndEvent.bind(this);
        this.provideResizeEndEvent();
        this.writeToSessionStorage = this.writeToSessionStorage.bind(this);
        this.readFromSessionStorage = this.readFromSessionStorage.bind(this);
        // different definition on xxl than mixins.scss
        this.breakpoints = {
            sm: 0,
            md: 640,
            lg: 960,
            xl: 1200,
            xxl: 2560,
        };
        this.settings = {
            fixed_header_height_mobile_megamenu: 110,
            fixed_header_height_mobile_legacy: 50,
            fixed_header_height_tablet_megamenu: 117,
            fixed_header_height_default: 138,
        };
        this.viewportWidth = window.innerWidth;
        this.resizedOnce = false;
        String.prototype.hashCode = function () {
            let hash = 0;
                let i;
                let chr;
            if (this.length === 0) return hash;
            for (i = 0; i < this.length; i++) {
                chr = this.charCodeAt(i);
                hash = ((hash << 5) - hash) + chr;
                hash |= 0; // Convert to 32bit integer
            }
            return Math.abs(hash);
        };
    }

    provideResizeEndEvent() {
        this.resizeEndEvent = document.createEvent('Event');
        this.resizeEndEvent.initEvent('resizeend', false, true, {});

        let resizeTimeout;

        window.onresize = () => {
            clearTimeout(resizeTimeout);
            resizeTimeout = setTimeout(() => {
                // Only dispatch resize if the width has changed. Reason: iOS Safari address bar
                if (window.innerWidth !== this.viewportWidth || !this.resizedOnce) {
                    this.resizedOnce = true;
                    this.viewportWidth = window.innerWidth;
                    window.dispatchEvent(this.resizeEndEvent);
                }
            }, 350);
        };
    }

    isBusinessPage() {
        return $('.page.magat.business').length > 0;
    }

    isEditMode() {
        return $('.editmode').length > 0 && window !== window.top;
    }

    isPreviewMode() {
        return $('.previewmode').length > 0 && window !== window.top;
    }

    isWebPSupported() {
        const elem = document.createElement('canvas');
        if (elem.getContext && elem.getContext('2d')) {
            return elem.toDataURL('image/webp').indexOf('data:image/webp') === 0;
        }
        return false;
    }

    getHeaderHeight() {
        const megamenu = $('.has-megamenu').length;

        if (this.isMobile()) {
            return megamenu ? this.settings.fixed_header_height_mobile_megamenu : this.settings.fixed_header_height_mobile_legacy;
        }

        return this.isTablet() && megamenu ? this.settings.fixed_header_height_tablet_megamenu : this.settings.fixed_header_height_default;
    }

    getBannerHeight() {
        let bannerHeight = 0;
        if ($('.cmp-banner').length > 0 && $('.cmp-banner').is(':visible')) {
            bannerHeight = $('.cmp-banner').outerHeight();
        }
        return bannerHeight;
    }

    getFixedContainerHeight(iteration) {
        const increment = iteration || 0;
        const fixedContainer = document.querySelector('.fixed-container');
        if (fixedContainer !== null) {
            return fixedContainer.offsetHeight;
        }
        if (document.querySelector('.experience-page').length === 0) {
            // loop until .fixed-container is present
            if (increment === 10) {
                console.error('It seems .fixed-container is not present. Could not calculate Fixed Container Height');
                return undefined;
            }
            return this.getFixedContainerHeight(increment + 1);
        }
        return 0;
    }

    /**
     * Only use this method if window.resize event is unavailable!
     */
    onElementHeightChange(elm, callback) {
        let newHeight;
        (function run(lastHeight) {
            newHeight = elm.clientHeight;
            if (typeof lastHeight === 'undefined') {
                callback(newHeight);
            }
            if (typeof lastHeight !== 'undefined' && lastHeight !== newHeight) {
                callback(newHeight);
            }
            if (elm.onElementHeightChangeTimer) {
                clearTimeout(elm.onElementHeightChangeTimer);
            }
            elm.onElementHeightChangeTimer = setTimeout(run(newHeight), 200);
        })();
    }

    // checks if the viewport contains the element
    elementIsVisible(element) {
        const $window = jQuery(window);
        const viewportTop = $window.scrollTop();
        const viewportHeight = $window.height();
        const viewportBottom = viewportTop + viewportHeight;
        const $elem = jQuery(element);
        const {top} = $elem.offset();
        const height = $elem.height();
        const bottom = top + height;

        return (top >= viewportTop && top < viewportBottom)
            || (bottom > viewportTop && bottom <= viewportBottom)
            || (height > viewportHeight && top <= viewportTop && bottom >= viewportBottom);
    }

    slideDown(target, duration = 1000) {
        target.style.removeProperty('display');
        let {display} = window.getComputedStyle(target);

        if (display === 'none') {
            display = 'block';
        }

        target.style.display = display;
        const height = target.offsetHeight;
        target.style.overflow = 'hidden';
        target.style.height = 0;
        target.style.paddingTop = 0;
        target.style.paddingBottom = 0;
        target.style.marginTop = 0;
        target.style.marginBottom = 0;
        target.offsetHeight;
        target.style.boxSizing = 'border-box';
        target.style.transitionProperty = 'height, margin, padding';
        target.style.transitionDuration = `${duration}ms`;
        target.style.height = `${height}px`;
        target.style.removeProperty('padding-top');
        target.style.removeProperty('padding-bottom');
        target.style.removeProperty('margin-top');
        target.style.removeProperty('margin-bottom');
        window.setTimeout(() => {
            target.style.removeProperty('height');
            target.style.removeProperty('overflow');
            target.style.removeProperty('transition-duration');
            target.style.removeProperty('transition-property');
        }, duration);
    }

    isMobile() {
        return window.innerWidth < 640;
    }

    isTablet() {
        return window.innerWidth >= 640 && window.innerWidth < 960;
    }

    isSmallDesktop() {
        return window.innerWidth >= 960 && window.innerWidth < 1200;
    }

    isDesktop() {
        return window.innerWidth > 1200;
    }

    generateUuidv4() {
        if (typeof crypto === 'undefined') {
            window.crypto = window.msCrypto;
        }
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) => (
            // eslint-disable-next-line no-mixed-operators,no-bitwise
            c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16));
    }

    setCookie(name, value, lifespan) {
        const secure = window.location.protocol === 'https:' ? 'secure;' : '';
        let expires;
        if (typeof lifespan !== 'undefined') {
            const date = new Date();
            date.setSeconds(date.getSeconds() + lifespan);
            expires = `; expires=${date.toUTCString()}`;
        } else {
            expires = '';
        }
        document.cookie = `${name}=${value || ''}${expires};domain=${window.location.hostname};path=/;${secure}`;
    }

    getCookie(name) {
        name += '=';
        const decodedCookie = (document.cookie);
        const ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i += 1) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                c = c.substring(name.length, c.length);
                return c;
            }
        }
        return false;
    }

    deleteCookie(name) {
        return new Promise((resolve, reject) => {
            this.setCookie(name, '', -86400);
            let iterations = 0;
            const waitForDeletion = window.setInterval(() => {
                if (!this.getCookie(name)) {
                    window.clearInterval(waitForDeletion);
                    resolve(true);
                }
                if (iterations > 50) {
                    window.clearInterval(waitForDeletion);
                    reject(new Error(`Cookie ${name} could not be deleted.`));
                }
                iterations += 1;
            }, 350);
        });
    }

    applyTabFocusRestrictor(param) {
        const element = param instanceof jQuery ? $(param)[0] : param;
        if (element.classList.contains('focus-restricted')) return;
        element.classList.add('focus-restricted');
        element.addEventListener('keydown', this.tabFocusRestrictor);
    }

    tabFocusRestrictor(e) {
        if (!(e.key === 'Tab' || e.keyCode === 9)) return;
        const focusableEls = $(e.currentTarget).find(this.focusableSelector);
        const tabFocusData = {
            element: e.currentTarget,
            firstFocusableEl: focusableEls[0],
            lastFocusableEl: focusableEls[focusableEls.length - 1],
        };
        if (e.shiftKey) /* shift + tab */ {
            if (document.activeElement === tabFocusData.firstFocusableEl) {
                tabFocusData.lastFocusableEl.focus();
                e.preventDefault();
            }
        } else if (document.activeElement === tabFocusData.lastFocusableEl) {
            tabFocusData.firstFocusableEl.focus();
            e.preventDefault();
        }
    }

    killTabFocusRestrictor(param) {
        const element = param instanceof jQuery ? $(param)[0] : param;
        if (element) {
            element.removeEventListener('keydown', this.tabFocusRestrictor);
            element.classList.remove('focus-restricted');
        }
    }

    elementJump(selector) {
        if (!selector) {
            return;
        }
        setTimeout(() => {
            let itemToScroll = $(selector);
            if (itemToScroll.length === 0) {
                return;
            }
            if (itemToScroll[0] && itemToScroll[0].tagName !== 'DIV') {
                itemToScroll = itemToScroll.closest('div');
            }
            // add 5px to header height to take care of headers bottom shadow
            const headerHeight = this.getHeaderHeight() + 15;
            $([document.documentElement, document.body]).animate({
                scrollTop: $(itemToScroll).offset().top - headerHeight,
            }, 400);
        }, 1);
    }

    ensureVisibility(element) {
        const conditionalVisibilityElems = $(element).parents('.cmp-conditionalvisibility');
        if (conditionalVisibilityElems.length > 0) {
            if (!conditionalVisibilityElems.is(':visible')) {
                conditionalVisibilityElems.show();
                const sumoSelects = conditionalVisibilityElems.find('.SumoSelect');
                if (sumoSelects.is(':visible')) {
                    sumoSelects.removeClass('disabled');
                }
            }
        }
    }

    log(namespace, msg) {
        if (window.location.search.indexOf('debug=true') !== -1) {
            console.log(`${namespace}: ${msg}`);
        }
    }

    readFromSessionStorage(key) {
        return sessionStorage.getItem(key);
    }

    writeToSessionStorage(key, value) {
        sessionStorage.setItem(key, value);
    }

    /**
     * Calls a link given by the data-link-href attribute on the given element
     * @param elem the element, where the href is edited
     */
    callLinkCallback(elem) {
        const dataLink = $(elem);
        const href = dataLink.data('link-href');

        if (!href) {
            return;
        }

        const target = dataLink.data('link-target') || '_self';

        if (!href.startsWith('#')) {
            window.open(href, target);
        }
    }

    isIOS() {
        return [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod',
        ].includes(navigator.platform)
        // iPad on iOS 13 detection
        || (navigator.userAgent.includes('Mac') && 'ontouchend' in document);
    }
}

export default BaseUseClass;
